<template>
  <div>
    <b-container
      v-if="$route.meta.breadcrumbNavbar"
      fluid
      class="breadcrumb-navbar-mobile"
    >
      <b-row>
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-start breadcrumb-height overflow-hidden"
        >
          <div
            v-if="$route.meta.breadcrumbNavbar"
            class="breadcrumb-wrapper"
          >
            <b-breadcrumb>
              <b-breadcrumb-item
                to="/"
              >
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
          <div
            v-if="$route.meta.breadcrumbNavbar"
            ref="scrollBreadcrumbMobile"
            class="breadcrumb-wrapper breadcrumb-mobile"
          >
            <b-breadcrumb class="pl-0 pr-0">
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumbNavbar"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ $t(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BContainer, BRow, BCol, BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.meta.breadcrumbNavbar === undefined) {
          document.body.classList.add('no-breadcrumbnavbar')
        } else if (document.body.className.match('no-breadcrumbnavbar')) {
          document.body.classList.remove('no-breadcrumbnavbar')
        }
      },
    },
  },
  created() {
    window.addEventListener('resize', this.scrollBreadcrumbMobile)
  },
  updated() {
    this.scrollBreadcrumbMobile()
  },
  destroyed() {
    window.removeEventListener('resize', this.scrollBreadcrumbMobile)
  },
  methods: {
    scrollBreadcrumbMobile() {
      if (this.$refs.scrollBreadcrumbMobile !== undefined) {
        this.$refs.scrollBreadcrumbMobile.scrollBy(this.$refs.scrollBreadcrumbMobile.scrollWidth, 0)
      }
    },
  },
  setup() {
    return { }
  },
}
</script>

<style lang="scss" scoped>
.breadcrumb-navbar-mobile .breadcrumb{
  display: flex;
  flex-wrap: nowrap;
}

.breadcrumb-navbar-mobile .breadcrumb .breadcrumb-item{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.breadcrumb-navbar-mobile .breadcrumb .breadcrumb-item:last-child{
  flex-shrink: 0;
}

.breadcrumb-mobile{
  overflow: auto;
}
</style>
