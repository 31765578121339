<template>
  <div>
    <div
      v-if="$route.meta.breadcrumbNavbar"
      class="breadcrumb-wrapper"
    >
      <b-breadcrumb>
        <b-breadcrumb-item to="/">
          <feather-icon
            icon="HomeIcon"
            size="16"
            class="align-text-top"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item
          v-for="item in $route.meta.breadcrumbNavbar"
          :key="item.text"
          :active="item.active"
          :to="item.to"
        >
          {{ $t(item.text) }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
  </div>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
  },
  setup() {
    return { }
  },
}
</script>
